<script>
import PageHeader from "@/components/page-header.vue";
import InquiresList from "@/views/pages/inquires/components/List.vue";

export default {
  name: "Index",
  components: {
    PageHeader,
    InquiresList
  },
  data() {
    return {
      title: "Inquires",
      breadcrumbs: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Inquires",
          active: true
        }
      ]
    };
  }
}
</script>

<template>
  <PageHeader :title="title" :items="breadcrumbs"/>
  <div class="mb-4">
    <InquiresList/>
  </div>
</template>

<style scoped>

</style>