<script>
import SwiperCore, {Navigation, Pagination, Thumbs} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";
import "swiper/swiper-bundle.css";
import axios from "axios";
import Swal from "sweetalert2";
import userStore from '@/state/store'
import {CountTo} from "vue3-count-to";

SwiperCore.use([Thumbs, Pagination, Navigation]);

//  Simple Pie Charts
function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
      if (color) {
        color = color.replace(" ", "");
        return color;
      } else return newValue;
    } else {
      var val = value.split(',');
      if (val.length == 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}

export default {
  name: "StatisticsByManagers",
  components: {
    Swiper, SwiperSlide,
    CountTo
  },
  data() {
    return {
      statistics: [],
      STATUSES: {
        pending: 'warning',
        success: 'success',
        fail: 'danger',
        quoted: 'secondary'
      },
      is_loading: true,

    }
  },
  computed: {
    statistics_total() {
      try {
        return this.statistics.reduce((acc, item) => acc + item.total, 0)
      } catch {
        return 0
      }
    },
    statistics_chart() {
      try {
        const flattenedStatuses = this.statistics.reduce(
            (acc, item) => acc.concat(item.statuses),
            []
        );
        let statistics = flattenedStatuses.reduce((acc, status) => {
          if (status) {
            acc[status.status] = (acc[status.status] || 0) + status.count;
          }
          return acc;
        }, {});
        return {
          series: Object.values(statistics),
          chartOptions: {
            title: {
              text: 'Statistics - ' + this.statistics.map(i => parseInt(i.total)).reduce((a, b) => a + b, 0),
              style: {
                fontWeight: 500,
              },
            },
            chart: {
              height: 150,
              type: "pie",
            },
            labels: Object.entries(statistics).map(([status, count]) => status + ` - ${count}`),
            dataLabels: {
              dropShadow: {
                enabled: false,
              },
              formatter: (val, aa) => aa.w.config.series[aa.seriesIndex],
              showPercentValues: false, // Add this line to show the data values instead of percentages
            },
            colors: getChartColorsArray('["--vz-warning", "--vz-secondary", "--vz-success", "--vz-danger"]'),
          },
        }
      } catch {
        return {}
      }
    },

    user() {
      return userStore.state.user
    },

    managers_overall_performance_percentage() {
      return (this.statistics.map(user => (user.performance_percentage || 0)).reduce((a, b) => a + b, 0) / (this.statistics.length > 0 ? this.statistics.length : 1)).toFixed(1)
    },
    managers_overall_grade() {
      if (this.managers_overall_performance_percentage >= 90) {
        return 'A'
      } else if (this.managers_overall_performance_percentage >= 70) {
        return 'B'
      } else if (this.managers_overall_performance_percentage >= 50) {
        return 'C'
      } else {
        return 'D'
      }
    }
  },
  methods: {
    async getStatistics() {
      try {
        this.is_loading = true
        let response = await axios.get('/inquiry/statistic/')
        this.statistics = response.data
            .map(inquiry => {
              return {
                ...inquiry,
                success_ratio: this.getInquirySuccessRatio(inquiry),
                chart: {
                  series: inquiry.statuses.map(i => i.count || 0),
                  chartOptions: {
                    title: {
                      text: (inquiry.manager ? inquiry.manager.username || 'Unknown' : 'Unknown') + ` - ${inquiry.total || 0}`,
                      style: {
                        fontWeight: 500,
                      },
                    },
                    chart: {
                      height: 450,
                      type: "pie",
                    },
                    labels: inquiry.statuses.map(i => i.status + ` - ${i.count || 0}`),
                    dataLabels: {
                      dropShadow: {
                        enabled: false,
                      },
                      formatter: (val, aa) => aa.w.config.series[aa.seriesIndex],
                      showPercentValues: false, // Add this line to show the data values instead of percentages
                    },
                    colors: getChartColorsArray('["--vz-warning", "--vz-secondary", "--vz-success", "--vz-danger"]'),
                  },
                }
              }
            }).sort((a, b) => b.success_ratio - a.success_ratio);
        this.is_loading = false
      } catch {
        this.is_loading = false
        let Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
        await Toast.fire({
          icon: "error",
          title: "Error while getting statistics"
        });
        this.statistics = []
      }
    },
    getInquirySuccessRatio(inquiry) {
      return (inquiry.statuses.find(i => i.status === 'Success').count / inquiry.total * 100).toFixed(2)
    },
    getTotalSuccessRatio() {
      return (this.statistics.reduce((acc, item) => acc + item.statuses.find(i => i.status === 'Success').count, 0) / this.statistics_total * 100).toFixed(2)
    },
    getManagerRatioByStatusCount(total_count, status_count) {
      return (status_count / (total_count || 1) * 100).toFixed(1)
    }
  },
  mounted() {
    this.getStatistics()
  }
}
</script>

<template>
  <swiper v-if="user.role.toLowerCase() === 'admin'" class="cryptoSlider" :slidesPerView="1"
          :loop="false" :spaceBetween="24"
          :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }"
          :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
              }"
          :breakpoints="{
                640: {
                  slidesPerView: 1.5,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                },
              }"
  >
    <swiper-slide>
      <b-card no-body>
        <b-card-body>
          <apexchart class="apex-charts" height="150" dir="ltr" :series="statistics_chart.series"
                     :options="statistics_chart.chartOptions"></apexchart>
          <div class="table-responsive">
            <table class="table table-sm table-borderless table-nowrap mb-0">
              <tbody>
              <tr>
                <th class="pb-0">Success ratio</th>
                <td class="pb-0 text-end">
                  {{ getTotalSuccessRatio() }}%
                </td>
              </tr>
              <tr>
                <th class="pb-0">Performance</th>
                <td class="pb-0 text-end">
                  <img v-b-tooltip.hover :title="`Excellent - ${managers_overall_performance_percentage}%`"
                       v-if="(managers_overall_grade || '').toLowerCase() === 'a'"
                       src="@/assets/images/letter-a.png"
                       style="width: 20px; height: 20px" alt="KPI Score - A">
                  <img v-b-tooltip.hover :title="`Good - ${managers_overall_performance_percentage}%`"
                       v-if="(managers_overall_grade || '').toLowerCase() === 'b'"
                       src="@/assets/images/letter-b.png"
                       style="width: 20px; height: 20px" alt="KPI Score - B">
                  <img v-b-tooltip.hover :title="`Bad - ${managers_overall_performance_percentage}%`"
                       v-if="(managers_overall_grade || '').toLowerCase() === 'c'"
                       src="@/assets/images/letter-c.png"
                       style="width: 20px; height: 20px" alt="KPI Score - C">
                  <img v-b-tooltip.hover :title="`Very Bad - ${managers_overall_performance_percentage}%`"
                       v-if="(managers_overall_grade || '').toLowerCase() === 'd'"
                       src="@/assets/images/letter-d.png"
                       style="width: 20px; height: 20px" alt="KPI Score - D">
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </b-card-body>
      </b-card>
    </swiper-slide>

    <template v-if="!is_loading">
      <swiper-slide v-for="(item, index) of statistics" :key="`statistics_by_manager_${index}`">
        <b-card no-body>
          <b-card-body>
            <apexchart class="apex-charts" height="150" dir="ltr" :series="item.chart.series"
                       :options="item.chart.chartOptions"></apexchart>
            <div class="table-responsive">
              <table class="table table-sm table-borderless table-nowrap mb-0">
                <tbody>
                <tr>
                  <th class="pb-0">Success ratio</th>
                  <td class="pb-0 text-end">{{ (item.success_ratio) }}%</td>
                </tr>
                <tr class="align-middle">
                  <th class="pb-0">Performance</th>
                  <td class="pb-0 text-end">
                    <img v-b-tooltip.hover :title="`Excellent - ${item.performance_percentage || 0}%`"
                         v-if="(item.overall_grade || '').toLowerCase() === 'a'"
                         src="@/assets/images/letter-a.png"
                         style="width: 20px; height: 20px" alt="KPI Score - A">
                    <img v-b-tooltip.hover :title="`Good - ${item.performance_percentage || 0}%`"
                         v-if="(item.overall_grade || '').toLowerCase() === 'b'"
                         src="@/assets/images/letter-b.png"
                         style="width: 20px; height: 20px" alt="KPI Score - B">
                    <img v-b-tooltip.hover :title="`Bad - ${item.performance_percentage || 0}%`"
                         v-if="(item.overall_grade || '').toLowerCase() === 'c'"
                         src="@/assets/images/letter-c.png"
                         style="width: 20px; height: 20px" alt="KPI Score - C">
                    <img v-b-tooltip.hover :title="`Very Bad - ${item.performance_percentage || 0}%`"
                         v-if="(item.overall_grade || '').toLowerCase() === 'd'"
                         src="@/assets/images/letter-d.png"
                         style="width: 20px; height: 20px" alt="KPI Score - D">
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </b-card-body>
        </b-card>
      </swiper-slide>
    </template>
  </swiper>

  <template v-else>
    <template v-for="manager in statistics" :key="`manager_${manager}`">
      <div class="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center gap-4 mb-4">
        <div class="w-100">
          <b-card no-body class="card-animate overflow-hidden mb-0">
            <b-card-body style="z-index:1 ;">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 overflow-hidden">
                  <p class="text-uppercase fw-medium text-truncate text-muted mb-3">
                    All Inquiries
                  </p>
                  <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                    <count-to :startVal="0" :endVal="manager.total || 0" :duration="3000"></count-to>
                  </h4>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </div>
        <template v-for="status in manager.statuses" :key="`status_${status}`">
          <div class="w-100">
            <b-card no-body class="card-animate overflow-hidden w-100 mb-0">
              <b-card-body style="z-index:1 ;">
                  <div class="flex-grow-1 overflow-hidden">
                    <p class="text-uppercase fw-medium text-truncate mb-3"
                       :class="`text-${STATUSES[status.status.toLowerCase()] || 'muted'}`">
                      {{ status.status }} INQUIRIES
                    </p>
                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                      <count-to :startVal="0" :endVal="status.count || 0" :duration="3000"></count-to>
                    </h4>
                  </div>
              </b-card-body>
            </b-card>
          </div>
        </template>
      </div>
    </template>
  </template>
</template>

<style scoped>

</style>