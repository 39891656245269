<script>
import Swal from "sweetalert2";
import axios from "axios";

function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
      if (color) {
        color = color.replace(" ", "");
        return color;
      } else return newValue;
    } else {
      var val = value.split(',');
      if (val.length == 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}

export default {
  name: "MonthlyStatistics",
  data() {
    return {
      statistics: [],
      is_loading: true,
      is_open: false,

      chart: {
        series: [
          {
            name: "Pending",
            data: [],
          },
          {
            name: "Quoted",
            data: [],
          },
          {
            name: "Success",
            data: [],
          },
          {
            name: "Fail",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: 250,
            stacked: true,
            stackType: "40%",
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          stroke: {
            width: 1,
            colors: ["#fff"],
          },
          title: {
            text: "Monthly statistics",
            style: {
              fontWeight: 600,
            },
          },
          xaxis: {
            categories: [],
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
          fill: {
            opacity: 1,
          },
          legend: {
            position: "top",
            horizontalAlign: "left",
            offsetX: 40,
          },
          colors: getChartColorsArray('["--vz-warning", "--vz-secondary", "--vz-success", "--vz-danger"]'),
        },
      },
      MONTHS: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
      },


    }
  },
  methods: {
    async getStatistics() {
      this.is_open = !this.is_open
      if (!this.is_open) return
      try {
        this.chart.chartOptions.xaxis.categories = []
        this.chart.series.forEach(item => item.data = [])
        let response = await axios.get('/inquiry/monthly_statistics/')
        this.statistics = response.data

        // Update x-axis categories with month names and total counts
        this.chart.chartOptions.xaxis.categories = this.statistics.map(item => {
          const totalCount = item.statuses.map(i => i.count).reduce((a, b) => a + b, 0);
          return `${this.MONTHS[item.month]} - ${totalCount}`;
        });

        this.statistics.forEach(item => {
          item.statuses.forEach(status => {
            this.chart.series.find(i => i.name.toLowerCase() === status.name.toLowerCase()).data.push(status.count || 0)
          })
        })
        this.is_loading = false
      } catch {
        this.is_loading = false
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    }
  }
}
</script>

<template>
  <div @click="getStatistics()" class="hstack gap-2 flex-wrap mb-3">
    <a class="fw-medium text-decoration-underline cursor-pointer" data-bs-target="#collapseExample"
       data-bs-toggle="collapse">
      {{ is_open ? 'Close' : 'See monthly statistics' }}
    </a>
  </div>
  <b-collapse id="collapseExample">

    <b-spinner v-if="is_loading" label="Loading..."></b-spinner>
    <div v-else>
      <b-card no-body class="pt-4">
        <apexchart class="apex-charts" height="250" dir="ltr" :series="chart.series"
                   :options="chart.chartOptions"></apexchart>
      </b-card>
    </div>
  </b-collapse>
</template>

<style scoped>

</style>