<script>
export default {
  name: "PerformanceView",
  data() {
    return {
      showModal: false,
      inquiry: null
    }
  },
  methods: {
    openModal(inquiry) {
      if (inquiry && inquiry.id) {
        this.inquiry = inquiry
        this.showModal = true
      } else {
        alert("Inquiry not found")
      }
    }
  }
}
</script>

<template>
  <b-modal v-if="inquiry" v-model="showModal" title="" hide-footer hide-header centered>
    <div class="text-center py-4">
      <img style="height: 165px; max-width: 165px"
           v-if="inquiry.grade.toLowerCase() === 'a'"
           src="@/assets/images/performance-a.png"
           alt="KPI Score - A"
           class="mx-auto mb-4"
      >
      <img style="height: 165px; max-width: 165px"
           v-if="inquiry.grade.toLowerCase() === 'c'"
           src="@/assets/images/performance-c.png"
           alt="KPI Score - A"
           class="mx-auto mb-4"
      >
      <h1>
        <span class="text-success" v-if="(inquiry.grade || '').toUpperCase() === 'A'">Excellent</span>
        <span class="text-secondary" v-else-if="(inquiry.grade || '').toUpperCase() === 'B'">Good</span>
        <span class="text-danger" v-else-if="(inquiry.grade || '').toUpperCase() === 'C'">Bad</span>
      </h1>
    </div>
  </b-modal>
</template>

<style scoped>

</style>