<script>
import moment from "moment";

export default {
  name: "ViewText",
  data() {
    return {
      showModal: false,
      inquiry: null
    }
  },
  computed: {
    canShowModal() {
      return this.inquiry !== null;
    }
  },
  methods: {
    openModal(inquiry) {
      if (!inquiry) return alert('No inquiry found');
      this.inquiry = inquiry;
      this.showModal = true;
    },
    formatDateTime(value) {
      if (!value) return '-'
      return moment(value).format('LLL');
    },
    formatOperationTime() {
      if (this.inquiry.status.toLowerCase() === 'quoted') {
        return this.formatDateTime(this.inquiry.quoted_at)
      } else if (this.inquiry.status.toLowerCase() === 'success') {
        return this.formatDateTime(this.inquiry.success_at)
      } else if (this.inquiry.status.toLowerCase() === 'fail') {
        return this.formatDateTime(this.inquiry.fail_at)
      } else {
        return '-'
      }
    },
    getOperationSince() {
      if (this.inquiry.status.toLowerCase() === 'quoted') {
        return this.formatDateFromNow(this.inquiry.quoted_at)
      } else if (this.inquiry.status.toLowerCase() === 'success') {
        return this.formatDateFromNow(this.inquiry.success_at)
      } else if (this.inquiry.status.toLowerCase() === 'fail') {
        return this.formatDateFromNow(this.inquiry.fail_at)
      } else {
        return '-'
      }
    },
    inquiryPendingDuration(date) {
      if (date) {
        const now = moment();
        const orderDate = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ');
        const difference = now.diff(orderDate);

        const duration = moment.duration(difference);
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();

        return `${hours} hours ${minutes} minutes`;
      }
      return 'Invalid date';
    },
    formatDateFromNow(date) {
      if (!date) return ''
      return moment(date, 'YYYY-MM-DDTHH:mm:ssZ').fromNow();
    },
  },
}
</script>

<template>
  <b-modal v-if="inquiry" v-model="showModal" centered hide-footer size="lg">
    <template v-slot:title v-if="canShowModal">
      <h4 class="mb-0">
                  <span v-if="inquiry.status === 'success'"
                        class="badge rounded-pill border border-success text-success">Accepted</span>
        <span v-else-if="inquiry.status === 'fail'"
              class="badge rounded-pill border border-danger text-danger">Rejected</span>
        <span v-else-if="inquiry.status === 'pending'" class="badge rounded-pill border border-warning text-warning">Pending</span>
        <span v-else-if="inquiry.status === 'quoted'"
              class="badge rounded-pill border border-secondary text-secondary">Quoted</span>
      </h4>
    </template>

    <p v-if="inquiry.status.toLowerCase() !== 'pending'" class="text-muted lh-lg">
      This inquiry was marked as
      <b :class="{
        'text-secondary': inquiry.status.toLowerCase() === 'quoted',
        'text-danger': inquiry.status.toLowerCase() === 'fail',
        'text-success': inquiry.status.toLowerCase() === 'success',
        'text-warning': inquiry.status.toLowerCase() === 'pending',
      }">{{ inquiry.status.toUpperCase() }}</b>
      at {{ formatOperationTime() }} ({{ getOperationSince(inquiry.fail_at) }})
    </p>

    <p v-else class="text-muted lh-lg">
      This inquiry is <b class="text-warning">PENDING</b> since {{ formatDateTime(inquiry.created) }} ({{
        inquiryPendingDuration(inquiry.created)
      }})
    </p>

    <div style="font-size: 14px; padding-left: 10px" class="mb-4 border rounded-3 pt-2">
      <div class="mb-2 text-muted">Inquiry Message</div>
      <p class="mb-0" v-html="inquiry.text"></p>
    </div>

    <div class="d-flex justify-content-between align-items-center gap-3">
      <div class="d-flex gap-3">
        <div v-if="inquiry.sender" @mouseover="inquiry.sender.is_hovered = true"
             @mouseleave="inquiry.sender.is_hovered = false"
             :class="{'bg-light': inquiry.sender.is_hovered}"
             v-b-tooltip.hover title="Sender"
             class="d-flex align-items-center border p-2 rounded-3">
          <div class="avatar-xs flex-shrink-0 me-3">
            <div class="avatar-title bg-soft-success text-success rounded-circle">
              {{ inquiry.sender.username[0] }}
            </div>
          </div>
          <div class="flex-grow-1">
            <h5 class="fs-13 mb-0">
              <a class="text-body d-block"> {{ inquiry.sender.username }} </a>
            </h5>
            <small class="text-muted">Sender</small>
          </div>
        </div>
        <div v-if="inquiry.sales_manager" @mouseover="inquiry.sales_manager.is_hovered = true"
             @mouseleave="inquiry.sales_manager.is_hovered = false"
             :class="{'bg-light': inquiry.sales_manager.is_hovered}"
             v-b-tooltip.hover title="Sales Manager"
             class="d-flex align-items-center border p-2 rounded-3">
          <div class="avatar-xs flex-shrink-0 me-3">
            <div class="avatar-title bg-soft-success text-success rounded-circle">
              {{ inquiry.sales_manager.username[0] }}
            </div>
          </div>
          <div class="flex-grow-1">
            <h4 class="fs-13 mb-0">
              <a class="text-body d-block"> {{ inquiry.sales_manager.username }} </a>
            </h4>
            <small class="text-muted">Sales manager</small>
          </div>
        </div>
      </div>
      <div class="text-end">
        <div class="d-flex flex-column justify-content-start align-items-start gap-1">
          <span v-b-tooltip.hover title="Date created">
            <i class="mdi mdi-clock text-muted"></i>
            {{ formatDateTime(inquiry.created) }}</span>
          <span v-b-tooltip.hover title="Date modified">
            <i class="mdi mdi-pencil text-muted"></i>
            {{ formatDateTime(inquiry.modified) }}</span>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>

</style>