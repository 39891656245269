<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "InquiryToSuccess",
  emits: ['inquiry-marked-as-success'],
  data() {
    return {
      showModal: false,
      inquiry: null,
      order_number: '',
      ORDER_CHILD_TYPE: {
        container_order: 'orders_container_detail',
        wagon_order: 'orders_wagon_detail',
        wagon_empty_order: 'orders_empty_wagon_detail'
      }
    }
  },
  methods: {
    async openModal(inquiry) {
      if (inquiry) {
        this.inquiry = inquiry;
        this.order_number = inquiry.order ? inquiry.order.order_number : '';
        this.showModal = true;
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Inquiry not found!',
        });
      }
    },
    closeModal() {
      this.showModal = false;
    },
    async connectOrder() {
      try {
        await axios.put(`/inquiry/assign_order/${this.inquiry.id}/`, {
          order_number: this.order_number
        });
        this.$emit('inquiry-marked-as-success');
        await Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Inquiry marked as success!',
        });
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
      }
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="Mark Inquiry as Success" centered hide-footer>

    <template v-if="inquiry && inquiry.order">
      <p class="lh-lg">
        You have already connected the order
        <router-link @click="closeModal()"
                     class="link-success" :to="{name: ORDER_CHILD_TYPE[inquiry.order.child_type], params: {
          id: inquiry.order.order_number,
        }}"><b>{{ inquiry.order.order_number }}</b></router-link>
        to this inquiry.
      </p>
      <p>
        If you want to change the order, please type the new order number.
      </p>
    </template>


    <!-- Warning Alert -->
    <template v-else>
      <p class="lh-lg">
        Every successful inquiry must have an order connected to it in order to be marked as successful.
      </p>

      <div>
        <p class="lh-lg mb-1">Please, <b>TYPE THE ORDER NUMBER</b> if you have it or click 👉</p>

        <div class="dropdown">
        <span data-bs-toggle="dropdown"
              aria-haspopup="true" class="fw-bold text-success cursor-pointer text-decoration-underline">CREATE A NEW ORDER</span>.
          <div class="dropdown-menu dropdown-menu-end">
            <router-link @click="closeModal()"
                         :to="{name: 'create_container_order', query: {
                inquiry_id: inquiry ? inquiry.id : null,
              }}" class="dropdown-item notify-item py-2">
            <span class="fw-medium cursor-pointer align-middle">
              Container Order
              <i class="mdi mdi-arrow-right ms-1"></i>
            </span>
            </router-link>
            <router-link @click="closeModal()"
                         :to="{name: 'create_wagon', query: {
                inquiry_id: inquiry ? inquiry.id : null,
              }}" class="dropdown-item notify-item py-2">
            <span class="fw-medium cursor-pointer align-middle">
              Wagon Order
              <i class="mdi mdi-arrow-right ms-1"></i>
            </span>
            </router-link>
            <router-link @click="closeModal()"
                         :to="{name: 'create_empty_wagon', query: {
                inquiry_id: inquiry ? inquiry.id : null,
              }}" class="dropdown-item notify-item py-2">
            <span class="fw-medium cursor-pointer align-middle">
              Empty Wagon Order
              <i class="mdi mdi-arrow-right ms-1"></i>
            </span>
            </router-link>
          </div>
        </div>

      </div>
    </template>

    <form @submit.prevent="connectOrder()" class="mt-2 border-top pt-3">
      <label class="form-label">Order Number</label>
      <input v-model="order_number" class="form-control" placeholder="Type order number" type="number">
      <div class="mt-3">
        <b-button type="submit" class="w-100" variant="success">Mark as Success</b-button>
      </div>
    </form>
  </b-modal>
</template>

<style scoped>

</style>